import React from 'react'
import "./segmentsMainBanner.css"
// import SegmentImage from "../../images/contactMainBanner.png"
// import SegmentVideo from "../../images/segmentMainBanner.mp4"
import SegmentImage from "../../images/SegmentAgriImage.png";

export default function SegmentsMainBanner() {
  return (
    <div>
      <div>
        <div style={{ position: "relative" }}>
          <img className="segments-home-image" alt="" src={SegmentImage} />
          {/* <video 
            className="segments-home-image"
            src={SegmentVideo}
            loop
            autoPlay
            muted
          /> */}
          <div className="segments-image-text-box">
            <div className="segments-image-background-text">
              <h1 className="segments-image-topic">
                Our Segments
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
