import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./segmentAgriProduce.css";
import IconChevronright from "../../images/vector-6.svg";
// import PlaceholderImage from "../../images/SegmentAgriImage.png";
import PlaceholderImage from "../../images/SegmentAgriImage1.webp";

export default function SegmentAgriProduce() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="segmentAgri-layout-192">
        <div className="segmentAgri-container2">
          <img
            data-aos="fade-right"
            data-aos-duration="500"
            className="segmentAgri-placeholder-image-icon"
            alt=""
            src={PlaceholderImage}
          />
          <div className="segmentAgri-content3">
            <div className="segmentAgri-section-title">
              <div className="segmentAgri-content4">
                <div
                  className="segmentAgri-heading"
                  data-aos="fade-left"
                  data-aos-duration="500"
                >
                  Agri Produce
                </div>
                <div
                  className="segmentAgri-text1"
                  data-aos="fade-left"
                  data-aos-duration="500"
                >
                  Over the last couple of decades, the CIC’s Agri Produce
                  Cluster has been providing high quality staple foods to
                  satisfy the daily dietary requirements of Sri Lankans.
                  Combining traditional Sri Lankan nutritional principles with
                  world-class quality assurance standards, the CIC Agri Produce
                  cluster presents a diverse range of products to serve
                  mainstream consumers as well as targeted niche segments of the
                  market.
                </div>
              </div>
            </div>
            <div
              className="segmentAgri-actions2"
              data-aos="fade-left"
              data-aos-duration="700"
            >
              <a href="/agri-produce/" className="segmentAgri-button3">
                <div className="segmentAgri-button-child" />
                <div className="segmentAgri-read-more">Read More</div>
                <img
                  className="segmentAgri-button-item"
                  alt=""
                  src={IconChevronright}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
