import React, { useEffect } from 'react'
import "./segmentCrop.css"
import Aos from "aos";
import "aos/dist/aos.css";
import PlaceholderImage1 from "../../images/cropSegmentImage.webp"
// import PlaceholderImage1 from "../../images/cropSegmentImage1.png"
import CropSegVector from "../../images/vector-61.svg"

export default function SegmentCrop() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="cropSegment-layout-4">
            <div className="cropSegment-container3">
              <div className="cropSegment-content5">
                <div className="cropSegment-content6">
                  <div className="cropSegment-section-title">
                    <div className="cropSegment-content4">
                      <div className="cropSegment-heading" data-aos="fade-right" data-aos-duration="500">
                        Crop Solutions
                        </div>
                      <div className="cropSegment-text1" data-aos="fade-right" data-aos-duration="500">
                        The mainstay of CIC, the Crop Solutions cluster has been one of the key architects
                        responsible for the growth and development of Sri Lanka’s agriculture sector in
                        the past half a century. As the only entity in Sri Lanka to offer fully integrated
                        solutions, CIC’s Crop Solutions cluster has succeeded in providing local farmers
                        with the tools to benefit from modern agricultural practices.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cropSegment-actions2"  data-aos="fade-right" data-aos-duration="500">
                  <a href='/crop-solutions/' className="cropSegment-button3">
                    <div className="cropSegment-button-child" />
                    <div className="cropSegment-read-more">Read More</div>
                    <img className="cropSegment-button-item" alt="" src={CropSegVector} />
                  </a>
                </div>
              </div>
              <img
                data-aos="fade-left" data-aos-duration="500"
                className="cropSegment-placeholder-image-icon"
                alt=""
                src={PlaceholderImage1}
              />
            </div>
          </div>
    </div>
  )
}
