import React, { useEffect } from "react";
import "./segmentCare.css";
import Aos from "aos";
import "aos/dist/aos.css";
import IconChevronRight from "../../images/vector-61.svg";
import PlaceholderImage from "../../images/healthAndCareSegment1.webp";
// import PlaceholderImage from "../../images/healthAndCareSegment.png";

export default function SegmentCare() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="HealthCareSegment-layout-4">
        <div className="HealthCareSegment-container3">
          <div className="HealthCareSegment-content5">
            <div className="HealthCareSegment-content6">
              <div
                className="HealthCareSegment-section-title"
                data-aos="fade-right"
                data-aos-duration="500"
              >
                <div className="HealthCareSegment-content4">
                  <div className="HealthCareSegment-heading">{`Health & Personal Care`}</div>
                  <div className="HealthCareSegment-text1">
                    CIC’s Health and Personal Care Cluster plays an important
                    role in safeguarding the wellbeing of Sri Lankans by
                    offering high quality solutions to address their preventive,
                    curative and personal care needs.
                  </div>
                </div>
              </div>
            </div>
            <div
              className="HealthCareSegment-actions2"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              <a href="/health-care/" className="HealthCareSegment-button3">
                <div className="HealthCareSegment-button-child" />
                <div className="HealthCareSegment-read-more">Read More</div>
                <img
                  className="HealthCareSegment-button-item"
                  alt=""
                  src={IconChevronRight}
                />
              </a>
            </div>
          </div>
          <img
            data-aos="fade-left"
            data-aos-duration="500"
            className="HealthCareSegment-placeholder-image-icon"
            alt=""
            src={PlaceholderImage}
          />
        </div>
      </div>
    </div>
  );
}
